$black-faded: rgba(0, 0, 0, 0.8);

.main {
  padding: 24px 32px;
  background-color: #f5f5f5;
  min-height: 100%;

  .header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h4 {
      font-family: 'Outfit', sans-serif;
      font-weight: 700;
      font-size: 26px;
      line-height: 150%;

      letter-spacing: 0.025em;
      color: $black-faded;
    }

    button {
      margin-left: 12px;
      height: 38px;
      width: 38px;
    }
  }
}

.researchBoard {
  margin-top: 24px;

  .toolbar {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 24px;

    .integrations,
    .filter,
    .share,
    .export {
      background-color: #f5f5f5;
      color: #000000;
      font-weight: 600;
    }

    .filter {
      svg > * {
        fill: #000000;
      }
    }

    .filter,
    .filterActive {
      margin-left: 40px;
      margin-right: 12px;
    }

    .filterActive {
      background-color: #de005b;
      color: white;

      svg > * {
        fill: white;
      }
    }

    button {
      height: 32px;
      font-family: 'Outfit', 'sans-serif';
      text-transform: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #999;
      min-width: 92px;

      svg {
        margin-right: 8px;
        color: white;
      }
    }

    .create {
      color: white;
    }

    input {
      font-size: 14px;
      padding: 5px 5px;
    }

    .search {
      width: 320px;
      border: 1px solid #e8ecf0;
      border-radius: 6px;
    }

    .integrations {
      width: 154px;
      margin-left: 40px;
      margin-right: 40px;
    }

    .share {
      margin-left: 12px;
      margin-right: 12px;
    }

    .create {
      margin-left: auto;
    }
  }
}

.noExtracts,
.noVariables {
  text-align: center;
  max-width: 404px;
  margin: auto;
  padding: 60px 0;

  .title {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #222a35;
  }
  .text {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #222a35;
    text-align: left;
  }
  .mb36 {
    margin-bottom: 36px;
  }
  .mb16 {
    margin-bottom: 16px;
  }

  ol {
    padding-left: 18px;
  }
  .bold {
    font-weight: 700;
  }
}

.drawerMain {
  width: 520px;
  background-color: #f5f5f5;
  min-height: 100%;

  h5 {
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .textBlock {
    padding-top: 32px;
  }

  .drawerHeader {
    padding: 30px 0 0 30px;
    margin: 0;
    text-align: center;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.025em;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .icon {
      position: absolute;
      cursor: pointer;
      right: 35px;
    }
  }

  .drawerBody {
    min-width: 100%;
    padding: 24px 32px;

    .buttonCreateTag {
      text-transform: none;
      height: 40px;
    }

    .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > div {
        width: 100%;
        max-width: 326px;
        margin-bottom: 12px;
      }
    }

    .allTags {
      padding-top: 10px;

      .listButtons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin: 0 -6px;

        .tagButton {
          height: 32px;
          font-family: 'Outfit', 'sans-serif';
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          max-width: 200px;
          min-width: 80px;
          text-transform: none;
          margin: 6px;

          svg {
            & > * {
              fill: white;
              filter: drop-shadow(0px 0.1px 0px rgba(0, 0, 0, 1));
            }
          }
        }
        .drawerFooter {
          min-width: 100%;
          padding: 24px 32px;
        }
      }
    }
  }
}
