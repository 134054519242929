.noExtracts {
  width: 724px;
  height: 630px;

  padding: 48px 160px;
  gap: 24px;
  background: #fff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  text-align: center;
  margin: auto;

  .mainTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #222a35;
    margin-bottom: 36px;
  }

  .textExtracts {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #222a35;
    text-align: left;
  }

  .textExtractsBold {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #222a35;
    text-align: left;
  }

  .secondTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #222a35;
    text-align: left;
  }
  ol {
    padding-left: 20px;
  }
  .mb36 {
    margin-bottom: 36px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
}

.noVariables {
  width: 724px;
  height: 564px;

  padding: 48px 160px;
  gap: 24px;
  background: #fff;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  text-align: center;
  margin: auto;

  .mainTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #222a35;
    margin-bottom: 36px;
  }

  .textExtracts {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #222a35;
    text-align: left;
  }
    
  ul {
    padding-left: 24px;
  }

  .secondTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    color: #222a35;
    text-align: left;
  }
  ol {
    padding-left: 20px;
  }
  .mb36 {
    margin-bottom: 36px;
  }
  .mb16 {
    margin-bottom: 16px;
  }
}
