.main {
  padding: 16px;

  .container {
    .label {
      font-family: 'Outfit', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: rgba(0, 0, 0, 0.8);
    }

    .header {
      .title {
        font-family: 'Outfit', sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #031927;
      }
    }
    .body {
      margin: 16px 0;
      padding: 16px 0;
      border-top: 1px solid rgba(196, 196, 196, 0.3);
      border-bottom: 1px solid rgba(196, 196, 196, 0.3);

      .collapse {
        .title {
          margin-top: 24px;
        }
        .variable {
          padding: 16px 0;
          margin-top: 12px;
          border-top: 1px solid rgba(196, 196, 196, 0.3);
          .item {
            &:nth-child(2) {
              margin: 10px 0;
            }
            .label {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-right: 8px;
            }
          }
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .label {
          flex-grow: 1;
        }
        .field {
          width: 308px;
        }
        .fieldUpdateTag {
          flex-basis: 270px;
          background-color: #edeeef;
        }
      }
      .project {
        margin-bottom: 20px;
      }
      .comment {
        margin-bottom: 10px;
      }
      .marginBottom {
        margin-bottom: 10px;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      .create {
        font-family: 'Outfit', sans-serif;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #8c8b96;
      }
      .save {
        font-size: 14px;
        width: 160px;
      }
      .updateTag {
        font-size: 14px;
        width: 100px;
      }
    }
  }
}
