.main {
  padding: 48px 80px;

  h2,
  h3 {
    margin: 0;
  }

  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 43px;
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 120%;
  }

  .container {
    display: flex;

    .items {
      margin: 48px -8px 0 -8px;
      display: flex;
      flex-wrap: wrap;
    }

    .project,
    .team {
      background: #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      height: 224px;
      padding: 32px 24px 16px 24px;
      margin: 0 8px 16px 8px;
      cursor: pointer;
      width: 192px;
    }

    .circle {
      height: 96px;
      width: 96px;
      border-radius: 50%;
      background-color: #731963;
      color: white;
      font-size: 48px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    .title {
      margin-top: 32px;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 150%;
      word-break: break-word;
    }

    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }

    .projects {
      flex: 2;
    }

    .teams {
      flex: 1;

      .circle.create {
        background-color: #fff;
        color: #e8ecf0;
      }

      .team {
        .title {
          text-align: left;
        }
      }
    }
  }
}
