.container {
  text-align: center;
  max-width: 360px;
  margin: auto;

  .empty {
    margin: 34px 0 40px 0;
  }

  .list {
    text-align: left;
    font-size: 14px;
    line-height: 180%;
  }
}
.empty,
.list {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  opacity: 0.6;
}

.empty {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.025em;
}

.h2CreateProject {
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  opacity: 0.6;
  letter-spacing: 0.025em;
  color: rgba(0, 0, 0, 0.8);
}

.row {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
}

.rowEmoji {
  padding-top: 10px;
}

.center {
  text-align: center;
}
