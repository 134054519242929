.main {
  background-color: #fff;
  border-radius: 8px;
  color: #031927;

  .header {
    padding: 40px 35px 0 35px;
    text-align: center;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0.025em;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      position: absolute;
      cursor: pointer;
      right: 35px;
    }
  }

  .body {
    padding: 24px 32px 40px 32px;

    .fields {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > div {
        width: 100%;
        max-width: 326px;
        margin-bottom: 16px;
      }
    }

    .source {
      .content {
        margin-bottom: 30px;
      }
    }
  }

  .info {
    margin-left: 8px;
    cursor: pointer;
  }

  .label {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
  }

  .footer {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
  }

  .back {
  }

  .next {
  }
}

.changeColumns,
.changeExport,
.confirmTagDelete {
  padding: 12px 16px;
  font-family: 'Outfit', sans-serif;
  max-width: 352px;

  h3,
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    margin-top: 0;
    margin-bottom: 12px;
  }
  h4 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    font-family: 'Outfit', sans-serif;
  }

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;

    button {
      height: 32px;
      width: 94px;
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
    }
    .confirm {
      margin-left: 12px;
    }
  }
}
