.content {
  display: grid;
  grid-template-areas:
    'sidebar appbar'
    'sidebar app'
    'sidebar app';
  grid-template-rows: 48px 1fr;
  grid-template-columns: 48px 1fr;
}

.appbar,
.sidebar {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: #222a35;
}

.sidebar {
  left: 0;
  bottom: 0;
  width: 48px;
  padding: 6px;
  grid-area: sidebar;

  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  .project,
  .new {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-bottom: 6px;
  }
  .projects {
    display: flex;
    flex-direction: column;
    align-items: center;

    .project {
      background-color: #004e5d;
      line-height: 2;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .config {
    margin-top: auto;
    margin-bottom: 32px;
    path {
      fill: white;
    }
  }
}

.appbar {
  right: 0;
  left: 48px;
  height: 48px;
  padding: 20px 32px;
  grid-area: appbar;

  border-left: 1px solid #44546a;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
  }

  .toolbar {
    display: flex;
    align-items: center;
    color: white;

    .item {
      margin-right: 16px;
    }

    .user {
      font-family: 'Outfit', sans-serif;
      cursor: pointer;

      svg {
        margin-left: 8px;
      }
    }
  }
}

.app {
  grid-area: app;
  min-height: calc(100vh - 48px);
  background-color: #f7fafc;
}
