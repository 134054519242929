.appbar {
  right: 0;
  left: 48px;
  height: 48px;
  padding: 20px 32px;
  grid-area: appbar;

  border-left: 1px solid #222a35;
  background-color: #222a35;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    display: flex;
    align-items: center;
  }
}
.center {
  padding-top: 60px;
}

.main {
  padding: 48px;
  background-color: #fff;
  max-width: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  outline: none;
  box-shadow: 0px 12px 48px rgba(26, 33, 52, 0.11);
  border-radius: 4px;

  margin-top: 200px;
  height: 0 auto;
  margin: 0 auto;
  position: relative;
}
