* {
  box-sizing: border-box;
}

.fontOutfit {
  font-family: 'Outfit', sans-serif;
}

a {
  text-decoration: none;
}

button:hover {
  cursor: pointer;
}

.main {
  padding: 48px;
  background-color: #fff;
  max-width: 640px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  outline: none;
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}

.main.centered {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container {
  max-width: 320px;
  margin: 0 auto;
}

.container-getting-started {
  width: 544px;
  padding: 0px 112px !important;
}

.container-getting-started-buttons {
  width: 320px;
  margin: 0 auto;
}

.ml-auto {
  margin-left: auto !important;
}

.mr-auto {
  margin-right: auto !important;
}

.text,
.title,
.second-title,
.sub-title {
  font-family: 'Outfit', sans-serif;
  color: #031927;
  margin-top: 0;
}

.title,
.sub-title {
  font-weight: 700;
}

.title {
  line-height: 120%;
  font-size: 30px;
}

.second-title {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: #222a35;
}

.welcome-second-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
}

.empty-text {
  margin-top: 38px;
  margin-left: 19px;
  margin-bottom: 40px;
}

.sub-title {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.text {
  font-size: 16px;
  line-height: 150%;
}

.text-export {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #031927;
  font-style: normal;
}

.bold {
  font-weight: bold;
}

.light,
.link-sm {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #a7b2c0;
}

.mb-1 {
  margin-bottom: 24px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.mt-1 {
  margin-top: 24px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.wrap {
  flex-wrap: wrap;
}

.m-8 {
  margin: 8px;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: flex-end;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.m-0 {
  margin: 0;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-6 {
  margin-left: 6px;
}

button.small {
  height: 32px;
  padding: 6px 12px;
  font-size: 14px;
}

.col-title {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #031927;
}

.italic {
  font-style: italic;
}

.text-right {
  text-align: right;
}

.link-sm {
  text-align: right;
  text-decoration-line: underline;
  color: #4985c5;
}

.d-block {
  display: block;
}

.text-small,
.text-xs {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.text-xs {
  font-size: 12px;
}

.title-welcome {
  font-family: 'Outfit';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 60px;
  margin: 0px;
  text-align: center;
  color: #222a35;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #242a33;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #4f555c;
}

.mark-yellow {
  background-color: #ffff00;
}

.mark-green {
  background-color: #c8c862;
}

.transparent {
  background-color: transparent;
}

.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.links-word-break {
  max-width: 500px;
  word-break: break-all;
}

.goSignup{
  color: #031927 !important;
}