.main {
  min-height: 100%;
  padding: 16px 48px 56px 48px;
  background-color: #f7fafc;

  & > button {
    display: block;
    width: 120px;
    color: #031927;
    margin-left: auto;
    margin-right: 50px;
  }

  .buttonSm {
    height: 32px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.03em;
  }

  .container {
    h2 {
      margin-bottom: 64px;
      margin-left: 32px;
    }

    .content {
      display: flex;

      .options {
        max-width: 320px;

        button {
          margin-bottom: 14px;
        }
      }

      .paper {
        background: #fff;
        border: 1px solid #e2e8f0;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;

        margin-left: 32px;
        padding: 32px 96px;
        max-width: 640px;
        width: 100%;

        h2 {
          margin-bottom: 32px;
          margin-left: 0;
        }

        h4 {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 150%;
          color: #031927;
          margin-bottom: 8px;
        }

        button {
          display: block;
          margin-top: 32px;
        }

        .email {
          margin: 28px 0;
        }
      }
    }
  }
}
