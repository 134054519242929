.enhanced-table {
  thead {
    th {
      font-family: 'Outfit', 'sans-serif';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: fade-out(#222a35, 0.2);
      padding: 10px 24px;

      &:first-child {
        padding: 10px 0 10px 16px;
      }

      &:last-child {
        text-align: right;
        padding: 10px 16px 10px 1px;
      }
    }
  }

  tbody {
    td,
    div {
      font-family: 'Outfit', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #031927;
    }

    td {
      padding: 18px 24px;

      &:first-child {
        padding: 20px 0 20px 16px;
      }

      &:last-child {
        padding: 20px 16px 20px 0;
        text-align: right;

        button {
          height: 34px;
          width: 34px;
        }
      }

      .extract {
        min-width: 505px;
      }

      .source {
        display: block;
        max-width: 290px;
        overflow-wrap: break-word;
      }
    }
  }
}

.research-source {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-decoration-line: underline;
  color: #4985c5;
}

.tag {
  display: inline-block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 6px 10px;
  background: #4985c5;
  border-radius: 6px;
  white-space: nowrap;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
}
